import { ChangeEvent, FC, useEffect, useState, useTransition } from 'react';
import {
  Box,
  Button as MuiButton,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  InputBase,
  Popover,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { DisabledFade, HoverToolTip, Icon } from 'shared/ui';
import { useAppDispatch } from 'shared/hooks';
import { PRODUCT_KEY, SORTING } from 'shared/constants';
import { useTranslation } from 'react-i18next';
import { TableProductInfo } from 'shared/models';
import { DataGridPremium, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useParams } from 'react-router-dom';
import { setCatalogFilters, setCatalogSorting } from 'shared/slices';

const Button = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.info.main,
  borderRadius: 0,
  height: '30px',
  textTransform: 'none',
  paddingInline: '16px',
  paddingBlock: '8px',
  color: theme.palette.darksome?.main,
  textAlign: 'left',
  justifyContent: 'flex-start',
}));

interface Props {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  field: string;
  isNumberType: boolean;
  isEditing: boolean;
  sorting: { field: string; value: SORTING };
  filters: { field: string; values: string[] }[];
  items: TableProductInfo[];
  hidedItems: TableProductInfo[];
}

export const CatalogItemsFilter: FC<Props> = ({
  open,
  anchorEl,
  onClose,
  field,
  isNumberType,
  isEditing,
  sorting,
  filters,
  items,
  hidedItems,
}) => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id: catalogId } = useParams();

  const sortingTextASC = isNumberType ? t('Smallest To Largest') : t('A to Z');
  const sortingTextDESC = isNumberType ? t('Largest To Smallest') : t('Z to A');
  const isByCurrentColumnSorted = field === sorting?.field;

  const isSelectedASC = isByCurrentColumnSorted && sorting?.value === SORTING.ASC;
  const isSelectedDESC = isByCurrentColumnSorted && sorting?.value === SORTING.DESC;

  const [sortingValue, setSortingValue] = useState<SORTING | null>(
    isSelectedASC ? SORTING.ASC : isSelectedDESC ? SORTING.DESC : null
  );

  const [isPendingTransition, startTransition] = useTransition();

  const handleChangeSort = (value: SORTING) => {
    if (value === sorting?.value && isByCurrentColumnSorted) {
      setSortingValue(field === PRODUCT_KEY.DESC ? value : null);
    } else {
      setSortingValue(value);
    }

    startTransition(() => {
      if (value === sorting?.value && isByCurrentColumnSorted) {
        onSetDefaultSorting();
      } else {
        onChangeSort(field, value);
      }
    });
  };

  const [searchValue, setSearchValue] = useState('');
  const onChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  const UniqFilterItem = (props: { params: GridRenderCellParams }) => {
    const name = props.params.value;

    return (
      <Stack direction="row" sx={{ width: '100%' }}>
        <FormControlLabel
          checked={openedFilterValues?.includes(name)}
          onChange={() => onChangeOpenedFilterValues(name)}
          label={
            <Typography noWrap sx={{ color: 'darksome.main' }}>
              {name ?? '(Blanks)'}
            </Typography>
          }
          disabled={isEditing}
          control={
            <Checkbox icon={<Icon path="checkbox-icon" />} checkedIcon={<Icon path="checkbox-icon-checked" />} />
          }
          sx={{ m: 0, pl: '10px', bgcolor: 'info.main', width: '100%' }}
        />
      </Stack>
    );
  };

  const filterColumn: GridColDef[] = [
    {
      field: 'name',
      type: 'string',
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => <UniqFilterItem params={params} />,
      width: 194,
    },
  ];

  const [openedFilterValues, setOpenedFilterValues] = useState<string[]>([]);
  const onChangeOpenedFilterValues = (value: string) => {
    setOpenedFilterValues(() => {
      if (openedFilterValues.includes(value)) {
        return openedFilterValues.filter((v) => v !== value);
      } else {
        return [...openedFilterValues, value];
      }
    });
  };

  const isFirstFilter = filters?.[0]?.field === field;
  const isFilteredByThisField = filters?.map((f) => f.field)?.includes(field);

  const allUniqItemsValues = [
    ...new Set(
      (isFirstFilter ? [...hidedItems, ...items] : items).map(
        (item) => item[field as keyof TableProductInfo] as string
      )
    ),
  ];

  const customSort = (a: string, b: string) => {
    const aNum = parseFloat(a);
    const bNum = parseFloat(b);
    if (!isNaN(aNum) && !isNaN(bNum)) {
      return aNum - bNum;
    } else if (!isNaN(aNum) && isNaN(bNum)) {
      return -1;
    } else if (isNaN(aNum) && !isNaN(bNum)) {
      return 1;
    } else {
      return a.localeCompare(b);
    }
  };

  const isNoFiltering = allUniqItemsValues.length === openedFilterValues.length;

  const uniqItemsValues = allUniqItemsValues.sort().map((name, i) => ({ id: i, name }));

  const searchedItemsValues = uniqItemsValues
    .filter((item) => item.name)
    .filter(({ name }) => name.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
    .sort((a, b) => customSort(a.name.toString(), b.name.toString()));

  const sortedSearchedItemsValues = [...searchedItemsValues].sort((a, b) => customSort(a.name, b.name));

  useEffect(() => {
    if (open) {
      setSearchValue('');
      const filterItem = filters?.find((f) => f.field === field);

      if (!filterItem) {
        setOpenedFilterValues(allUniqItemsValues);
        return;
      }

      const values = filters?.find((f) => f.field === field)?.values ?? [];
      setOpenedFilterValues(values);
    }
  }, [open]);

  const handleToggleSelectAll = () => setOpenedFilterValues(isNoFiltering ? [] : allUniqItemsValues);

  const handleOK = () => {
    const isAllItemsSelected = allUniqItemsValues.length === openedFilterValues.length;
    const isNoSelectedItems = openedFilterValues.length === 0;

    if (isAllItemsSelected) {
      const updatedFilters = filters.filter((f) => f.field !== field);
      onChangeFilters(updatedFilters);
    }

    if (!isNoSelectedItems && !isAllItemsSelected) {
      let updatedFilters: { field: string; values: string[] }[];
      const filter = filters?.find((f) => f.field === field);

      if (filter) {
        updatedFilters = filters.map((f) => (f.field === field ? { ...f, values: openedFilterValues } : f));
      } else {
        updatedFilters = [...filters, { field, values: openedFilterValues }];
      }

      onChangeFilters(updatedFilters);
    }
    onClose();
  };

  const handleResetFilter = () => {
    onResetFilter(field);
    onClose();
  };

  const onChangeSort = (field: string, sorting: SORTING) =>
    catalogId && dispatch(setCatalogSorting({ catalogId, field, sorting }));
  const onSetDefaultSorting = () =>
    catalogId && dispatch(setCatalogSorting({ catalogId, field: PRODUCT_KEY.DESC, sorting: SORTING.ASC }));
  const onChangeFilters = (filters: { field: string; values: string[] }[]) =>
    catalogId && dispatch(setCatalogFilters({ catalogId, filters }));
  const onResetFilter = (field: string) => {
    const updatedFilters = filters.filter((f) => f.field !== field);
    catalogId && dispatch(setCatalogFilters({ catalogId, filters: updatedFilters }));
  };

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      sx={{ '& .MuiPaper-root': { borderRadius: '4px', ml: -4 } }}
    >
      <Stack
        sx={{
          width: '200px',
          bgcolor: palette.info.main,
          zIndex: 1000,
          py: '8px',
        }}
      >
        <Box sx={{ backgroundColor: 'info.main', borderRadius: '10px' }}>
          <Typography sx={{ px: '16px' }}>{t('Sort')}</Typography>

          <Button
            fullWidth
            onClick={() => handleChangeSort(SORTING.ASC)}
            sx={{
              bgcolor: sortingValue === SORTING.ASC ? 'primary.light' : 'info.main',
              '&:hover': { bgcolor: sortingValue === SORTING.ASC ? 'primary.light' : 'info.main' },
            }}
            disabled={isPendingTransition}
          >
            <Icon path={`sort-az-${isSelectedASC ? 'black' : 'grey'}`} sx={{ mr: 1 }} />
            {sortingTextASC}
          </Button>
          <Button
            fullWidth
            onClick={() => handleChangeSort(SORTING.DESC)}
            sx={{
              bgcolor: sortingValue === SORTING.DESC ? 'primary.light' : 'info.main',
              '&:hover': { bgcolor: sortingValue === SORTING.DESC ? 'primary.light' : 'info.main' },
            }}
            disabled={isPendingTransition}
          >
            <Icon path={`sort-za-${isSelectedDESC ? 'black' : 'grey'}`} sx={{ mr: 1 }} />
            {sortingTextDESC}
          </Button>
        </Box>

        <HoverToolTip
          title={t('Can’t change while editing')}
          disableHoverListener={!isEditing}
          disableFocusListener={!isEditing}
          disableTouchListener={!isEditing}
          placement="bottom"
        >
          <Box sx={{ position: 'relative' }}>
            <InputBase
              value={searchValue}
              onChange={onChangeSearchValue}
              placeholder={t('search') + '...'}
              sx={{ px: '16px' }}
              startAdornment={<Icon path={'grey-search'} sx={{ mr: 0.5 }} />}
            />
            <Divider />

            {!searchValue && (
              <Stack direction="row" sx={{ width: '100%' }}>
                <FormControlLabel
                  checked={allUniqItemsValues.length === openedFilterValues.length}
                  onChange={handleToggleSelectAll}
                  label={<Box sx={{ color: 'darksome.main' }}>{t('Select All')}</Box>}
                  disabled={isEditing}
                  control={
                    <Checkbox
                      icon={<Icon path="checkbox-icon" />}
                      checkedIcon={<Icon path="checkbox-icon-checked" />}
                    />
                  }
                  sx={{ m: 0, pl: '10px', bgcolor: 'info.main', width: '100%', border: 'none' }}
                />
              </Stack>
            )}

            {sortedSearchedItemsValues.length ? (
              <>
                <DataGridPremium
                  rowHeight={36}
                  columnHeaderHeight={0}
                  hideFooter
                  columns={filterColumn}
                  rows={sortedSearchedItemsValues}
                  sx={{
                    minHeight: 0,
                    maxHeight: '200px',
                    overflowX: 'hidden',
                    p: 0,
                    border: 'none',
                    borderRadius: 0,

                    '& .MuiDataGrid-cell': {
                      padding: '0 !important',
                      border: 'none',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'info.dark',
                        borderRadius: '30px',
                      },
                      '&::-webkit-scrollbar': {
                        width: '4px',
                        backgroundColor: 'info.light',
                      },
                    },
                  }}
                />

                <DisabledFade open={isEditing} />
              </>
            ) : (
              <Typography sx={{ p: '10px 18px', fontWeight: 600, textAlign: 'center' }}>No data</Typography>
            )}
          </Box>
        </HoverToolTip>

        <Divider />
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ px: '10px' }}>
          {isFilteredByThisField && (
            <HoverToolTip title={t('Can’t change while editing')} disableHoverListener={!isEditing}>
              <IconButton onClick={handleResetFilter} disabled={isEditing}>
                <Icon path={'reset-filters-grey'} />
              </IconButton>
            </HoverToolTip>
          )}

          <Stack direction="row" alignItems={'center'} sx={{ ml: 'auto', height: '36px' }}>
            <Button onClick={onClose} sx={{ minWidth: 'auto', borderRadius: 1 }} disabled={isPendingTransition}>
              {t('cancel')}
            </Button>
            <Button
              onClick={handleOK}
              sx={{ px: '5px', borderRadius: 1, minWidth: 'auto', color: 'primary.main' }}
              disabled={isPendingTransition}
            >
              {t('Ok')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Popover>
  );
};
